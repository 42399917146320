<!--
 * @Descripttion: 对齐弹窗
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-14 15:24:40
 * @LastEditors: tangjz
 * @LastEditTime: 2023-07-11 18:10:49
-->
<template>
  <section class="alignment-dialog">
    <el-dialog v-model="dialogTableVisible" ref="dialogRef" :title="title" :show-close="status" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="alignment-dialog-content">
          <section class="card not-alignment" v-show="!status">
            <div class="team-card">
              <div class="input">
                <el-input id="search" v-model="searchInput" placeholder="搜索部门/姓名" clearable @change="changeSearch" @keyup.enter="changeSearch"></el-input>
                <div class="btn" @click="search">
                  <svg class="search-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9ZM13.9849 15.2575C12.6176 16.3481 10.8849 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 10.8849 16.3481 12.6176 15.2575 13.9849L19.0536 17.781C19.405 18.1324 19.405 18.7022 19.0536 19.0536C18.7022 19.405 18.1324 19.405 17.781 19.0536L13.9849 15.2575Z" fill="#fff"/>
                  </svg>
                </div>
              </div>
              <AlignMenu :data="teamData" class="journal-list-left" ref="okrTeamCatalogueRef" @select="queryUserJournal"></AlignMenu>
              <!-- <OkrTeamCatalogue
                class="journal-list-left"
                ref="okrTeamCatalogueRef"
                @select="queryUserJournal"
                :data="teamData">
              </OkrTeamCatalogue> -->
            </div>
            <div class="selece-okr-card">
              <AlignmentConetentList ref="alignDataRef" :data="alignmentCheckboxList" :alignmentList="alignmentList" :currId="currId" :allData="allAlignmentCheckboxList" @select="getAlignmentConetentList" :closeData="closeData" :dialogVisible="dialogTableVisible"></AlignmentConetentList>
            </div>
          </section>
          <section :class="{'maxwidth': status, 'card': true, 'alignment': true}">
              <AlignmentConetentList :alignment='true' :data="clickAlignment" @closeEven="closeEven"></AlignmentConetentList>
          </section>

        </div>
        <div class="btn-list" v-if="!status">
          <el-button round @click="cancel">取消</el-button>
          <el-button class="success" @click="success" round>确定</el-button>
        </div>
    </el-dialog>

  </section>
</template>

<script>
// import OkrTeamCatalogue from '@/views/layout/work/okr/component/OkrTeamCatalogue'
import AlignmentConetentList from '@/views/layout/work/okr/component/alignment/AlignmentConetentList'
import { getTeamList } from '@/apis/journal.js'
import { getOkrDeptList, getOkrSelect } from '@/apis/okr.js'
import { reactive, toRefs, onMounted, computed, nextTick, watch } from 'vue'
import eventBus from '@/utils/eventBus.js'
import AlignMenu from './alignment/Alignmenu.vue'

export default {
  name: 'AlignmentDialog',
  components: {
    // OkrTeamCatalogue,
    AlignmentConetentList,
    AlignMenu

  },
  props: {
    title: {
      typeof: String,
      default: () => ''
    },
    cycleId: {
      typeof: Number,
      default: () => 0
    },
    currId: { // 当前的id
      typeof: Number,
      default: () => 0
    },
    data: {
      typeof: Array,
      default: () => []
    },
    status: { // 是否仅查看对齐数据
      typeof: Boolean,
      default: () => false
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      teamData: {},
      title: computed(() => props.title),
      cycleId: computed(() => props.cycleId),
      dialogTableVisible: false,
      searchInput: '', // 查询文本框
      clickAlignment: [], // 选中的数据
      closeData: '',
      status: computed(() => props.status),
      alignmentList: [],
      okrTeamCatalogueRef: null,
      data: computed(() => props.data),
      currId: computed(() => props.currId),
      alignmentCheckboxList: [], // 用户对齐目标数据选项
      allAlignmentCheckboxList: [], // 总数据集合
      alignDataRef: null,
      leftData: []
      // teamData: computed(() => { return getTeamData(props.data) }),
    })
    onMounted(() => {
      // 处理传入的内容
      const setJson = new Set()
      if (props.data?.length) {
        props.data.forEach(item => {
          getArrayLayered(item.children, item)

          item.children.forEach(citem => {
            setJson.add(citem)
          })
        })
      }

      Array.from(setJson).length && (state.alignmentList = Array.from(setJson))

      // 获取我的团队
      getMyTeam()

      // 获取全部列表
      selectList({})
    })

    const clickItem = (item, status = true) => {
      if (item.item) {
        item = item.item
        status = item.status
      }
      console.log(item)
      if (item.dept_id) {
        console.log(111)
        emit('select', { type: 'dept', id: item.dept_id })
        // getLeftData(item)
      } else {
        console.log(222)
        emit('select', { type: 'user', id: item.id })
      }
    }

    // 获取我的团队信息
    const getMyTeam = async () => {
      const data = { type: 2 }
      const { code, data: teamData } = await getTeamList(data)
      if (code === 0) state.teamData = teamData
    }

    const queryUserJournal = ({ type, id }) => {
      selectList(type === 'dept' ? { deptId: id } : { userId: id })
    }

    // 处理数组层级
    const getArrayLayered = (array, typeList) => {
      if (array) {
        // 遍历数据，将okr与目标放置同一级别
        array.forEach((item) => {
          const resetItem = {
            id: item.id,
            align_objective_id: item.id,
            name: item.name,
            tag: item.tag
          }

          // 存在items时，存入类型
          if (typeList) {
            item.type = typeList.type
            if (item.type === 1) {
              item.dept = {
                name: typeList.name,
                dept_id: typeList.dept_id
              }
            } else if (item.type === 2) {
              item.user = {
                id: typeList.id,
                name: typeList.name,
                avatar: typeList.avatar
              }
            }
          }

          item.key_results = [...[resetItem], ...item.key_results]
        })
      }
    }

    watch(() => state.dialogTableVisible, (newValue) => {
      newValue && state.alignDataRef && state.alignDataRef.getInitAlign()
    })

    // 获取对齐信息
    const selectList = async ({ userId = '', deptId = '' }) => {
      const params = {
        dept_id: deptId,
        user_id: userId,
        cycle_id: state.cycleId
      }
      const { code, data } = await getOkrSelect(params)
      if (code === 0) {
        // 遍历数据，将okr与目标放置同一级别
        getArrayLayered(data.children)

        if (userId === '' && deptId === '') {
          state.allAlignmentCheckboxList = data.children
        }
        state.alignmentCheckboxList = data.children
      }
    }

    const closeEven = (item) => {
      state.closeData = item
    }

    // 获取选中的数据
    const getAlignmentConetentList = (obj) => {
      state.clickAlignment = obj
    }

    const cancel = () => {
      state.alignDataRef && state.alignDataRef.remakeDate()
      state.dialogTableVisible = false
    }

    const open = () => {
      state.dialogTableVisible = true

      nextTick(() => {
        if (document.getElementById('search')) {
          document.getElementById('search').onkeydown = function (event) {
            var e = window.event || event
            if (e.keyCode === 13 && !justNull()) {
              // 回车执行查询
              search()
            }
          }
        }
      })
    }

    const success = () => {
      emit('select', state.clickAlignment)
      state.dialogTableVisible = false
    }

    const justNull = () => {
      return state.searchInput.length === 0
    }

    // 搜索
    const search = async () => {
      const params = { name: state.searchInput }
      const { code, data } = await getOkrDeptList(params)
      // 成功情况下执行
      if (code === 0) {
        // getTeamData(data)
        state.teamData = { data, type: state.searchInput.length === 0 ? '' : 'search' }
      }

      if (justNull()) {
        // 为空情况下执行
        selectList({})
      } else {
        const getDataOne = (dataOne) => {
          return dataOne.length > 0 && dataOne[0]
        }
        // 获取第一条数据
        const dataOne = getDataOne(data.children) || getDataOne(data.user)

        if (dataOne) {
          state.okrTeamCatalogueRef.clickItem(dataOne, false)
        } else {
          state.alignmentCheckboxList = []
        }

        // console.log(dataOne)
      }
    }

    const changeSearch = () => {
      if (justNull()) {
        search()
      }
    }

    eventBus.$on('alignDialogInit', () => selectList({}))

    return {
      queryUserJournal,
      getMyTeam,
      getAlignmentConetentList,
      closeEven,
      cancel,
      open,
      success,
      search,
      changeSearch,
      clickItem,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.alignment-dialog {
  :deep(.el-dialog) {
    min-width: 961px;
    height: 525px;
    background: #F6F9FA;
    border-radius: 6px;
    .el-dialog__header {
      padding-top: 17px;
    }
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
    }
    .el-dialog__body {
      padding: 5px 14px 12px 16px;
    }

    .el-dialog__headerbtn {
      display: flex;
      align-items: center;
      margin-top: 2px;
    }

    .alignment-dialog-content {
      display: flex;
      height: 424px;
    }
    .card {
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    }

    .not-alignment {
      display: flex;
      border-radius: 6px;
      .team-card {
        padding: 16px 0 0 16px;
        width: 238px;
        overflow: hidden;
        background: #F1F4F4;

        .input {
          position: relative;
          margin-right: 16px;
          display: flex;
          align-items: center;
          .el-input__inner {
            padding-right: 35px;
            height: 33px;
            border-radius: 32px 0 0 32px;
            &:hover {
              border-color: #999;
            }
            &:focus {
               border-color: @active-text-color;
            }
          }

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 33px;
            border-radius: 0 32px 32px 0;
            cursor: pointer;
            background: @active-text-color;
          }
        }

        .journal-list-left {
          margin-top: 19px;
        }
      }
      .selece-okr-card {
        width: 342px;
        border-radius: 6px;
      }
    }
    .alignment {
      margin-left: 9px;
      border-radius: 6px;
      // width: 342px;
      width: 100%;

    .alignment {
      margin-left: 0;
    }

      &.maxwidth {
        width: 100%;
         .alignment {
           width: calc(100% - 18px);

           .icon {
             display: none;
           }
           .text-content {
             width: calc(100% - 15px);
           }
         }
      }
    }
  }
  .btn-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    .el-button {
      width: 85px;
      min-height: 32px;
      height: 32px;
      color: @active-text-color;
      background: #E4E3FC;
      &.is-round {
        padding: 5px 0;
      }
      &.success {
        color: #fff;
        background: @active-text-color;
      }
      &+.el-button {
        margin-left: 8px;
      }
      &:active,
      &:hover {
        border-color: @active-text-color;
      }
    }
  }
}
</style>
