<!--
 * @Descripttion: 对齐内容列表
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-15 11:09:29
 * @LastEditors: tangjz
 * @LastEditTime: 2023-06-26 17:09:09
-->
<template>
  <section class="alignment-conetent">
      <div v-if="data.length">
        <p class="title" v-if="alignment">已对齐目标：</p>
      </div>
      <el-scrollbar>
           <el-checkbox-group v-model="notAlignmentList" @change="chang">
             <template v-for="(item, index) in data" :key="index">
                <ul :class="{'alignment-conetent-li': true, 'alignment': alignment}" v-if="currId != item.id">
                  <li class="alignment-conetent-item">
                    <!-- type 为1 部门 2个人 -->
                      <p class="compnay-info" v-if="item.type === 1">
                          <span>
                            <img src="@/assets/img/company.svg" />
                            {{item.dept?.name}}
                          </span>
                          <span class="cycle">{{item.cycle}}</span>
                      </p>
                      <div class="user-info" v-else>
                          <div class="user-logo">
                            <UserAvatar :user="item.user" size="24" fontSize="10" medalSize="36"></UserAvatar>
                            <span>{{item.user.name}}</span>
                          </div>
                          <span class="cycle">{{item.cycle}}</span>
                      </div>
                  </li>

                  <li class="alignment-conetent-item">
                      <ul class="select-list">
                          <AlignmentConetentItem ref="alignmentConetentItemRef"
                              :data="item.key_results"
                              :close="alignmentStatus"
                              v-if="item.key_results"
                              :parent="parents(item)"
                              @closeEven="closeEven">
                          </AlignmentConetentItem>
                      </ul>
                  </li>
                </ul>
             </template>
            </el-checkbox-group>
            <div v-if="!data.length">
              <div v-if="!alignment" class="content-default">
                <img src="@/assets/img/work/company-part.svg" alt="">
                <p class="alignment-default">请选择需要对齐的部门</p>
              </div>
              <div v-else class="content-default">
                <img src="@/assets/img/work/align-target.svg" alt="">
                <p class="alignment-default">暂无对齐目标</p>
              </div>
            </div>
      </el-scrollbar>
  </section>
</template>

<script>
import { computed, reactive, toRefs, onMounted } from 'vue'
import AlignmentConetentItem from '@/views/layout/work/okr/component/alignment/AlignmentConetentItem'
import { watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
export default {
  name: '',
  components: {
    AlignmentConetentItem
  },
  props: {
    alignment: { // 是否为对齐目标状态
      typeof: Boolean,
      default: false
    },
    data: { // 获取列表数据
      typeof: [Array, Object],
      default: []
    },
    allData: { // 获取列表数据
      typeof: [Array, Object],
      default: []
    },
    alignmentList: {
      typeof: [Array, Object],
      default: []
    },
    currId: { // 当前的id
      typeof: Number,
      default: () => 0
    },
    closeData: {
      typeof: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const state = reactive({
      notAlignmentList: [], // 未对齐目标
      alignmentList: computed(() => props.alignmentList), // 已对其目标
      alignmentStatus: computed(() => props.alignment),
      data: computed(() => props.data),
      allData: computed(() => props.allData),
      closeData: computed(() => props.closeData),
      alignmentConetentItemRef: null,
      routerId: route.params.id,
      notAlignmentListOperate: [],
      dialogVisible: computed(() => props.dialogVisible)
    })

    onMounted(() => {
      initAlignmentList()
      getInitAlign()
    })

    // 传入初始化值
    const initAlignmentList = () => {
      const kDataSet = new Set()
      const alignmentList = state.alignmentList
      if (alignmentList.length > 0) {
        alignmentList.forEach(item => {
          item.key_results.forEach(kitem => {
            // reorganizationData 重新组装数据
            kDataSet.add(JSON.stringify(state.alignmentConetentItemRef.reorganizationData(kitem)))
          })
        })
        state.notAlignmentList = Array.from(kDataSet)
        // console.log(state.notAlignmentList)
      }
    }

    const justData = (json) => {
      const justId = (item, items) => {
        return (item.align_objective_id && item.id === items.id) || (item.objective_id && item.objective_id === items.id)
      }
      const justJson = state.allData

      const add = []

      for (let data of json) {
        data = JSON.parse(data)
        for (const list of justJson) {
          if (justId(data, list)) {
            const parent = { ...list }
            let type = true

            add.forEach((addItem) => {
              if (justId(data, addItem)) {
                addItem.key_results.push(data)
                type = false
              }
            })

            if (type) {
              parent.key_results = [{ ...data }]
              add.push(parent)
            }
          }
        }
      }

      if (add.length > 0) {
        // 排序里面的数据

        add.forEach(item => {
          item.key_results.sort((i, j) => {
            return i.id > j.id ? 1 : -1
          })
        })
      }

      return add
    }

    const parents = (item) => {
      // if (item.type === 1) {
      //   return { dept_id: item.dept_id }
      // } else {
      //   return { user_id: item.user_id }
      // }
    }

    const closeEven = (item) => {
      emit('closeEven', JSON.stringify(item))
    }

    const chang = () => {}

    watch(() => state.notAlignmentList, (json) => {
      // 记录已经选中过的内容
      emit('select', justData(json))
    })

    // 执行删除
    watch(() => state.closeData, (value) => {
      const array = []
      state.notAlignmentList.forEach((item) => {
        if (item !== value) {
          array.push(item)
        }
      })
      state.notAlignmentList = array
    })

    const remakeDate = () => {
      if (JSON.stringify(state.notAlignmentListOperate) !== JSON.stringify(state.notAlignmentList)) {
        state.notAlignmentList = state.notAlignmentListOperate
        emit('select', justData(state.notAlignmentList))
      }
    }

    const getInitAlign = () => {
      state.notAlignmentListOperate = state.notAlignmentList
    }

    return {
      ...toRefs(state),
      justData,
      parents,
      chang,
      closeEven,
      remakeDate,
      getInitAlign
    }
  }
}
</script>

<style lang="less" scoped>
.alignment-conetent {
    padding: 20px 0 0 16px;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 6px;

    .title {
        margin-bottom: 20px;
        color: #333;
        font-size: 13px;
    }

    .alignment-conetent-li {
        margin-bottom: 32px;

        &:last-child.alignment {
            margin-bottom: 64px;
        }

        .compnay-info,
        .user-info {
            margin-right: 16px;
            padding-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            font-size: 13px;
            border-bottom: 1px solid #F1F1F1;

            .user-logo {
              display: flex;
              align-items: center;
            }

            span {
              display: flex;
              align-items: center;
            }

            .cycle {
              min-width: 70px;
              justify-content: flex-end;
            }

            img,
            .user-avatar {
                margin-right: 8px;
            }
        }
    }
}
.content-default{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 354px;
  .alignment-default {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
    margin-top: 11px;
  }
}

</style>
