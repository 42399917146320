<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-11-14 09:59:56
 * @LastEditTime: 2023-04-12 14:52:49
 * @LastEditors: wangjuan
 * @FilePath: \sns_web\src\views\layout\work\okr\component\alignment\Alignmenu.vue
-->
<template>
  <div v-if="data.type !== 'search'" class="scroll-bar">
    <p @click="clickItem(alignData)" class="company-dept">{{alignData.name}}</p>
    <el-menu
      active-text-color="#606266"
      background-color="#f1f4f4"
      default-active="2"
      text-color="#606266"
      :unique-opened="true"
      @select="selcetItem"
    >
      <template v-if="alignData.children?.length">
        <AlignmentConetentItem v-for="(item, index) in alignData.children"
          :key="item.name"
          :item="item"
          :level="String(item.level)"
          :tabindex="String(index)"
          @selectMenu="selectMenu"
        ></AlignmentConetentItem>
      </template>
      <template v-if="alignData.user?.length">
        <el-menu-item v-for="user in alignData.user" :key="user" @click="clickItem(user)">
          <span>{{user.name}}</span>
        </el-menu-item>
      </template>
      </el-menu>
  </div>
  <el-scrollbar v-else :class="{'margintop': !type}">
    <ul class="dep-ul">
      <!-- {{leftData}} -->
      <li v-for="item in leftData.children" :key="item.name" :class="{'active': (clickId === item.dept_id && item.dept_id) || clickId === item.id}">
        <template v-if="item.dept_id">
          <span :class="{'dep-item':true}" @click="clickItem(item, false)">
            <img src="@/assets/img/company.svg" />
            {{item.name}}
          </span>
        </template>
      </li>
      <li v-for="item in leftData.user" :key="item.name" :class="{'active': (clickId === item.dept_id && item.dept_id) || clickId === item.id}">
          <div :class="{'dep-item':true, 'user-item':true}" @click="clickItem(item, false)">
            <UserAvatar :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
            <span>{{ item.name }}</span>
          </div>
      </li>
    </ul>
  </el-scrollbar>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import AlignmentConetentItem from './AlignmenuItem.vue'

export default {
  name: 'OkrAlignMenu',
  components: {
    AlignmentConetentItem
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      data: computed(() => {
        return JSON.parse(JSON.stringify(props.data))
      }),
      okrMenuRef: null,
      type: computed(() => props.data.type),
      alignData: [],
      leftData: []
    })
    state.alignData = state.data

    watch((props), ({ data: { type, data } }) => {
      state.leftData = getTeamData(data)
    })
    // 获取组织架构
    const getTeamData = (data) => {
      // 左侧渲染数据
      getLeftData(data)
      console.log(data)
      return data
    }
    // 左边的数据
    const getLeftData = (data) => {
      state.leftData = []
      data.children && state.leftData.push(...data.children)
      data.user && state.leftData.push(...data.user)
    }
    const clickItem = (item, status = true) => {
      if (item.item) {
        item = item.item
        status = item.status
      }
      if (item.dept_id) {
        emit('select', { type: 'dept', id: item.dept_id })
        // getLeftData(item)
      } else {
        emit('select', { type: 'user', id: item.id })
      }
    }

    const selcetItem = (key, keyPath) => {
      console.log(key, keyPath)
    }

    const selectMenu = ({ item, status }) => {
      clickItem(item, status)
    }
    return {
      ...toRefs(state),
      clickItem,
      selcetItem,
      selectMenu
    }
  }
}
</script>

<style scoped lang="less">
.scroll-bar {
  height: 350px;
  &::-webkit-scrollbar {
      width: 5px;
      height: 6px;
  }
  &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
  }
}
.company-dept {
  margin-bottom: 5px;
  cursor: pointer;
}
.el-menu {
  height: 100%;
  overflow-y: scroll;
}
:deep(.el-submenu__title), :deep(.el-menu-item) {
  height: 45px;
  border-radius: 8px;
  line-height: 45px;
}
:deep(.el-submenu__title:hover), :deep(.el-menu-item):hover {
  background-color: #fff !important;
  border-radius: 8px;
}
.dep-ul {
  height: 330px;
  .dep-item{
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    &:hover {
      cursor: pointer;
    }
    img{
      margin-right: 4px;
    }
  }
}
</style>
