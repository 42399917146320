<!--
 * @Description: 对齐
 * @Author: heqi
 * @Date: 2022-07-19 15:10:16
 * @LastEditTime: 2022-10-08 13:51:50
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\views\layout\work\okr\myokr\component\OkrAlignTarget.vue
-->
<template>
  <div class="target-wrapper">
    <div class="triangle"></div>

      <template v-if="datas.length">
        <ul>
        <li v-for="item in datas" :key="item">
          <div class="target-top">
            <div class="active-text-color">{{item.name}}—{{item.cycle}}</div>
            <div class="active-text-color other-align"><img src="@/assets/img/work/group-duiqi.svg">已对齐</div>
          </div>
          <div class="target-content">
            <div class="target-title" v-if="item.children[0]?.id"><img src="@/assets/img/work/work-okr-flag.svg"> <p>目标：{{item.children[0].name}}</p></div>
            <div class="target-list" v-if="item.children[0]?.key_results?.filter(i => i.objective_id)">
              <template  v-for="keys in item.children[0].key_results" :key="keys">
                <p v-if="keys.objective_id">
                <img src="@/assets/img/work/work-okr-ellipse.svg">
                  <span class="active-text-color">{{keys.tag}}:</span>
                  {{keys.name}}
                </p>
              </template>
            </div>
          </div>
        </li>
        </ul>
      </template>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const state = reactive({
      datas: computed(() => props.data)
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less">
.target-wrapper {
  z-index: 1;
  position: absolute;
  top: 53px;
  left: 63px;
  width: 502px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-bottom: 0;
  border-radius: 6px;
  box-shadow:2px 2px 5px #ccc;
  ul, li {
    position: relative;
    z-index: 1;
  }
  li .target-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 34px;
    font-weight: 700;
    font-size: 12px;
    border-bottom: 1px solid #F1F1F1;
  }
  li .target-content{
    padding: 16px;
    // padding-bottom: 0;
    // padding-top: 0;
    border-bottom: 1px solid #F1F1F1;
    .target-title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #333;
      // padding-top: 16px;
      margin-bottom: 10px;
      img {
        margin-right: 8px;
      }
    }
  }
  li .target-list{
    padding-left: 40px;
    p {
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 16px;
      color: #333333;
      line-height: 18px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        transform: translateY(-1px);
        margin-right: 8px;
        width: 6px;
        height: 6px;
      }
      span {
        display: inline-block;
        width: 42px;
      }
    }
  }
}
.triangle {
  position: absolute;
  left: 32px;
  top: -10px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border-width: 1px;
  border-style: solid;
  border-color: #F1F1F1 transparent transparent #F1F1F1;
  background: #fff;
  border-radius: 4px;
}
.other-align {
  display: flex;
  img {
    padding-right: 5px;
  }
}
</style>
