<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-11-14 10:57:24
 * @LastEditTime: 2024-08-29 10:06:05
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \sns_web\src\views\layout\work\okr\component\alignment\AlignmenuItem.vue
-->
<template>
    <el-submenu ref="subMenu" :index="getIndex()" popper-append-to-body>
      <template #title>
        <el-checkbox v-model="isCheckAll" :indeterminate="isIndeterminate"></el-checkbox>
        <span @click="clickItem(item, false)" class="click-width">{{item.name}}</span>
      </template>
      <AlignmenuItem
        v-for="(child, index) in item.children"
        :ref="el => { deptItemRef[index] = el }"
        :key="child.name"
        :item="child"
        :tabindex="String(index)"
        :level="getIndex()"
        @selectMenu="clickItem"
        @changeCheck="changeCheck"
      />
      <template v-if="item.user.length">
          <el-menu-item v-for="user in item.user" :key="user" @click="clickItem(user)">
            <el-checkbox v-model="user.checked" @change="changeCheck()" @click.stop="() =>{}"></el-checkbox>
            <span>{{user.name}}</span>
          </el-menu-item>
      </template>
    </el-submenu>
</template>

<script>
import { computed, reactive, toRefs, watch, nextTick } from 'vue'

export default {
  props: {
    item: {
      type: Object
    },
    tabindex: {
      type: String
    },
    level: {
      type: String
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      item: computed(() => props.item),
      tabindex: computed(() => props.tabindex),
      level: computed(() => props.level),
      isCheckAll: false,
      isIndeterminate: false,
      deptItemRef: [],
      checkedUserIds: []
    })

    const getIndex = (type) => {
      return state.level + '-' + state.tabindex
    }

    const clickItem = (item, status = true) => {
      if (item.status) {
        return emit('selectMenu', item)
      }
      emit('selectMenu', { item, status })
    }

    // 选择用户或者部门
    const changeCheck = () => {
      const checkedUserLength = state.item.user.filter(item => item.checked).length
      const checkedDeptLength = state.deptItemRef.filter(ref => ref.isCheckAll).length
      const isIndeterminateDeptLength = state.deptItemRef.filter(ref => ref.isIndeterminate).length
      const userLength = state.item.user.length
      const deptLength = state.deptItemRef.length
      state.isIndeterminate = (checkedUserLength > 0 && checkedUserLength <= userLength) || (checkedDeptLength > 0 && checkedDeptLength <= deptLength) || isIndeterminateDeptLength > 0
      if (checkedUserLength === 0 && checkedDeptLength === 0) {
        state.isCheckAll = false
      } else if (checkedUserLength === userLength && checkedDeptLength === deptLength && isIndeterminateDeptLength === 0) {
        state.isCheckAll = true
        state.isIndeterminate = false
      }
      nextTick(() => {
        emit('changeCheck')
      })
    }

    const getCheckedUserIds = (data = state.item.user) => {
      const userIds = data.filter(item => item.checked).map(item => item.id)
      state.deptItemRef.forEach(ref => {
        userIds.push(...ref.getCheckedUserIds(state.item.children.user))
      })
      return userIds
    }

    watch(() => state.isCheckAll, (newValue) => {
      // 勾选状态同步到子组件
      state.deptItemRef.forEach(ref => { ref.isCheckAll = newValue })
      state.item.user.forEach(user => { user.checked = newValue })
      changeCheck()
    })

    return {
      ...toRefs(state),
      getIndex,
      clickItem,
      changeCheck,
      getCheckedUserIds
    }
  }
}
</script>

<style scoped lang="less">
.click-width {
  display: inline-block;
  min-width: 180px;
}
:deep(.el-submenu__title), :deep(.el-menu-item) {
  height: 45px;
  border-radius: 8px;
  line-height: 45px;
}
:deep(.el-submenu__title:hover), :deep(.el-menu-item):hover {
  background-color: #fff !important;
  border-radius: 8px;
}
.el-checkbox{
  margin-right: 10px;
}
</style>
