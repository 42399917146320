<!--
 * @Descripttion: okr内容展示
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-19 14:12:43
 * @LastEditors: heqi
 * @LastEditTime: 2022-10-26 17:10:38
-->
<template>
   <div class="text-content">
        <span class="name">{{tag}}：</span>
        <span class="msg">{{name}}</span>
    </div>
    <div v-if="close" class="icon"><img src="@/assets/img/work/work-okr-alignment-close.svg" alt="close" @click.stop.prevent="clickClose"></div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
export default {
  name: 'okrContent',
  props: {
    tag: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => ''
    },
    close: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['closeEven'],
  setup (props, { emit }) {
    const state = reactive({
      tag: computed(() => props.tag),
      name: computed(() => props.name),
      close: computed(() => props.close)
    })
    const clickClose = () => {
      emit('closeEven', '')
    }
    return {
      ...toRefs(state),
      clickClose
    }
  }
}
</script>

<style lang="less" scoped>
.text-content {
    padding: 8px;
    width: 286px;
    color: #333;
    font-size: 13px;
    line-height: 20px;
    border-radius: 4px;
    white-space: break-spaces;
    background: #F1F4F4;
    .name {
        color: @active-text-color;
    }
}
.icon {
    margin-left: 10px;
    cursor: pointer;
    svg {
        width: 10px;
    }
}
</style>
