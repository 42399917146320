/*
 * @Description:
 * @Author: heqi
 * @Date: 2022-09-27 10:07:12
 * @LastEditTime: 2023-08-02 15:16:44
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\utils\okr\okrTools.js
 */
const getAlignName = (alignArr) => {
  if (!alignArr || !alignArr.length) return '对齐上级目标'
  const arr = []
  const alignData = JSON.parse(JSON.stringify(alignArr))

  if (alignData[0].children) {
    alignData.forEach(i => {
      if (!i.children[0].key_results.length) {
        arr.push(`${i.name}`)
      } else if (i.children[0].id) {
        const num = i.children[0].key_results.filter(j => j.tag.includes('KR')).length + 1
        arr.push(`${i.name}${num <= 1 ? '' : `(${num})`}`)
      } else if (!i.children[0].id) {
        const num = i.children[0].key_results.filter(j => j.tag.includes('KR')).length
        arr.push(`${i.name}${num <= 1 ? '' : `(${num})`}`)
      }
    })
    return arr.join('、')
  }
  // 自己保存选择的
  if (alignData.length === 1) {
    const keyRes = alignData[0].key_results
    // 单个目标下的多个kr type: 1-公司部门 非1-上级领导
    return (alignData[0].type === 1 ? alignData[0].dept.name : alignData[0].user.name) + ((keyRes.length > 1) ? `(${keyRes.length})` : '')
  } else { // 不同目标的多个kr 或者 目标
    alignData.forEach(i => {
      i.type === 1 ? arr.push(`${i.dept.name}${i.key_results.length && i.key_results.length !== 1 ? `(${i.key_results.length})` : ''}`) : arr.push(`${i.user.name}${i.key_results.length && i.key_results.length !== 1 ? `(${i.key_results.length})` : ''}`)
    })
    return arr.join('、')
  }
}

// 处理重新
const getAlignId = (alignData) => {
  const objId = []
  const krID = []
  alignData.forEach(i => {
    if (i.children) {
      if (i.children[0].type) {
        if (i.children[0].id) {
          !objId.includes(i.children[0].id) && objId.push(i.children[0].id)
        }
        i.children[0].key_results.length && i.children[0].key_results.forEach(j => {
          if (j.objective_id) {
            !krID.includes(j.id) && krID.push(j.id)
          }
        })
      } else {
        if (i.children[0].id) {
          !objId.includes(i.children[0].id) && objId.push(i.children[0].id)
        }
        i.children[0].key_results.length && i.children[0].key_results.forEach(j => {
          if (j.objective_id) {
            !krID.includes(j.id) && krID.push(j.id)
          }
        })
      }
    } else if (i.key_results?.length) {
      i.key_results.forEach(keyItem => {
        if (keyItem.align_objective_id) {
          objId.push(keyItem.align_objective_id)
        }
        if (keyItem.objective_id) {
          krID.push(keyItem.id)
        }
      })
    }
  })
  return { objId, krID }
}

export { getAlignName, getAlignId }
