<!--
 * @Descripttion: 选中/删除操作
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-19 14:12:43
 * @LastEditors: wangjuan
 * @LastEditTime: 2022-12-02 16:22:29
-->
<template>
  <template v-for="(item, index) in data" :key="index">
      <li :class="['select-item', close?'close':'chcekcout']">
        <template v-if="close">
            <okrContent :close="close" :tag="item.tag" :name="item.name" @closeEven="clickClose(item)"></okrContent>
        </template>
        <template v-else>
            <el-checkbox :label='children(item)'>
                <okrContent :tag="item.tag" :name="item.name"></okrContent>
            </el-checkbox>
        </template>
      </li>
  </template>
</template>

<script>
import okrContent from '@/views/layout/work/okr/component/alignment/okrContent'
import { computed, reactive, toRefs } from '@vue/reactivity'
export default {
  name: 'AlignmentConetentItem',
  components: {
    okrContent
  },
  emits: ['closeEven'],
  props: {
    data: { // 内容参数
      type: [Object, Array],
      default: () => {}
    },
    close: { // 是否为关闭状态
      type: Boolean,
      default: () => false
    },
    parent: { // 上级id
      type: [Object, Array],
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      data: computed(() => props.data),
      close: computed(() => props.close),
      parent: computed(() => props.parent)
    })
    // 处理数据进行返回
    const children = ({ ...item }) => {
      return JSON.stringify({ ...reorganizationData(item), ...state.parent })
    }

    // 触发关闭
    const clickClose = (item) => {
      emit('closeEven', item)
    }

    // 重组数据
    const reorganizationData = (item) => {
      if (item.align_objective_id) {
        return {
          id: item.id,
          align_objective_id: item.align_objective_id,
          name: item.name,
          tag: item.tag
        }
      } else {
        return {
          id: item.id,
          objective_id: item.objective_id,
          name: item.name,
          tag: item.tag
        }
      }
    }

    return {
      ...toRefs(state),
      clickClose,
      reorganizationData,
      children
    }
  }
}
</script>

<style lang="less" scoped>
.select-item {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &.chcekcout {
        :deep(.el-checkbox) {
            display: flex;
            align-items: center;
            .el-checkbox__label {
                padding-left: 8px;
            }
        }
    }
}
</style>
