<template>
  <div class="okr-template" :id="`comment_${okrData.id}`">
    <!-- 去评价页面以及 状态不为 自评提交 员工evaluate_status === 1 或者是 完成状态才显示okr 非去评价页面 模板 -->
    <EvaluateTemp v-if="
      (toEvalutePage && isAlignTheViewBtn) ||
      (okrData.status === 6 && isAlignTheViewBtn) ||
      (([1,2,3].includes(okrData.evaluate_status)) && !isEvaluate && isAlignTheViewBtn) ||
      (([1,2,3].includes(okrData.evaluate_status)) && route.name !== 'evaluation' && isAlignTheViewBtn)"
      :evaluteData="okrData"
      @evaluateSuccess="evaluateSuccess"
      @quitEvalute="goToEvalute"
      :showDepartment="showDepartment"
    >
      <template #evaluateFooter>
        <footer class="okr-template-bottom">
          <div class="okr-visible">
            <!-- 通过状态显示评论 -->
            <div class="cursor-pointer" @click="showComment(okrData)">
              <img src="@/assets/img/common/comment.svg" alt="">
              <span class="comment">评论({{okrData.comment_count}})</span>
            </div>

              <img src="@/assets/img/work/work-okr-view.svg">
              <span>{{okrData.scope === 1 ? '全部可见' : '仅本部门和上级可见'}}</span>
          </div>
        </footer>

        <!-- 评论列表 -->
        <template v-if="showComent">
          <UserComment
            :id="okrData.id"
            :userId="okrData.user_id"
            commentType="7"
            @updateCommentTotal="updateCommentTotal($event, item)"
            @hiddenComment="showComent = false"
          >
          </UserComment>
        </template>
      </template>
    </EvaluateTemp>
    <template v-else>
      <div class="okr-template-header active-text-color">
        <div class="okr-date" v-if="okrData.cycle">
          <span class="target-type" v-if="okrData.ork_type && showTargetType.includes(route.name)">{{okrData.ork_type}}</span>
          <template v-if="showDepartment">{{okrData.type === 2 ? okrData.user.name : okrData.dept.name}}—</template>
          {{getCycle(okrData.cycle)}}</div>
        <div class="okr-date" v-if="templateType === 'add'">{{date.year}}年 {{date.month}}</div>

        <!-- 评价状态 和完成状态不显示按钮 -->
        <template v-if="showRightBtn && templateType!=='evalution' && templateType !== 'complete'">
          <!-- 编辑按钮 -->
          <div v-if="templateType === 'add'" class="okr-quit-edit cursor-pointer" @click="quitEdit('okrAdd')">退出编辑</div>

          <!-- 不是新增且不是审核中就可以编辑 -->
          <div v-else-if="templateType !== 'add' && templateType !== 'checking'" class="okr-quit-edit okr-option cursor-pointer">
            <div @click="editOkr" v-if="!isEdit"><img src="@/assets/img/common/edit.svg">编辑</div>
            <div v-else class="okr-quit-edit cursor-pointer" @click="quitEdit">退出编辑</div>
            <div @click="deleteOkr(okrData.id)"><img src="@/assets/img/common/delete.svg">删除</div>
          </div>
        </template>
      </div>

      <!-- okr列表 -->
      <main class="okr-template-content" :class="[templateType === 'pass' && !isEdit ? 'pass-template' : '']">

      <!-- okr对齐 目标选择 -->
      <AlignmentDialog
        ref="alignmentDialogRef"
        @select="alignment"
        :title="okrData.cycle ? `${okrData.cycle}目标对齐` : `${date.year}年${date.month}目标对齐`"
        :cycleId="cycleId || okrData.cycle_id"
        :data="okrData.align_middle"
        :currId="isEdit ? Number(okrData.id) : undefined"
      >
      </AlignmentDialog>
      <!-- okr对齐 hover弹窗 -->

        <div class="okr-template-align-up" ref="alignTextRef" v-if="(isAlignTheViewBtn && okrData?.align_middle?.length) || (templateType === 'add') || isEdit">
          <img src="@/assets/img/work/work-okr-align.svg">
          <span class="cursor-pointer" @click="showAlign">{{alignText}}</span>
          <OkrAlignTarget v-if="alignTarget" :data="okrData.align_middle"></OkrAlignTarget>
        </div>

        <slot name="alignTheView"></slot>

        <div class="okr-template-target">

          <!-- 目标头部 -->
          <div class="okr-template-target-icon">
            <div>
              <img src="@/assets/img/work/work-okr-flag.svg">
              <span class="default-text-color okr-target">目标:</span>
              <!-- 新增和编辑显示删除 -->
              <el-input v-if="templateType === 'add' || isEdit" v-model="targetTitle" type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" placeholder="请输入目标" maxlength="100"></el-input>
              <div class="okr-target-text" v-else>{{targetTitle}}</div>
            </div>
            <!-- 总进度条 -->
            <div class="item-progress" v-if="(templateType === 'pass' && !isEdit) || (templateType === 'evalution' && !isEdit)">
              <el-progress :stroke-width="24" type="circle" :percentage="okrData.schedule" color="#7972f0" ><span></span></el-progress>
              <span class="active-text-color all-target-weight">{{okrData.schedule}}%</span>
            </div>

            <img src="@/assets/img/work/work-okr-flagLine.svg" v-if="(okrData?.alignData?.length) || (isAlignTheViewBtn && okrData?.align_middle?.length) || (templateType === 'add') || isEdit">
          </div>

          <!-- 列表内容 -->
          <div class="okr-template-target-wrapper">
            <!-- KR列表 -->
            <ul :class="showAddKrBtn && (templateType === 'add' || isEdit) ? 'show-line' : ''">
              <!-- <transition-group name="fade"> -->
                <!-- okr单项 -->
                <li class="target-item" v-for="(item, index) in okrList" :key="item">
                  <div class="target-item-left">
                    <img src="@/assets/img/work/work-okr-ellipse.svg">
                    <span>KR {{index + 1}}:</span>

                    <!-- 新增和编辑显示输入框 -->
                    <el-input v-if="templateType === 'add' || isEdit" v-model="item.name" :placeholder="`请输入关键结果${index + 1}`" maxlength="100"></el-input>
                    <div v-else class="item-kr-content word-wrap-block">{{item.name}}</div>
                  </div>

                  <div class="item-weight">
                    权重
                    <!-- 新增和编辑显示输入权重 -->
                    <el-input-number v-if="(templateType === 'draf') || isEdit || templateType === 'add'" v-model="item.weights" :precision="0" :min="0" value-on-clear="min" :max="getMaxNumber(index)" :controls="false" :disabled="item.disabled" @change="changeWeight(index)" @blur="inputNumberBlur(index)"/>
                    <!-- <el-input-number v-if="(templateType !== 'pass' && templateType !== 'evalution') || (isEdit && templateType !== 'evalution')" v-model="item.weights" :precision="0" :min="0" value-on-clear="min" :max="getMaxNumber(index)" :controls="false" :disabled="item.disabled" @change="changeWeight(index)" @blur="inputNumberBlur(index)"/> -->
                    <!-- 通过显示权重 -->
                    <!-- <span v-else-if="templateType === 'checking'">{{item.weights}}</span> -->
                    <span v-else>{{item.weights}}</span>
                    <span> %</span>
                  </div>

                  <!-- 新增和编辑显示删除 -->
                  <el-icon v-if="templateType === 'add' || isEdit" class="close-icon cursor-pointer" @click="deleteOkrItem(index, item)"><CloseBold /></el-icon>

                  <!-- 进度条 -->
                  <div class="item-progress" v-if="(templateType === 'pass' && !isEdit) || (templateType === 'evalution' && !isEdit)">
                    <el-progress :stroke-width="24" type="circle" :percentage="item.schedule" color="#7972f0" ><span></span></el-progress>
                    <el-input-number v-if="!disabled || (templateType === 'evalution' && (route.name === 'myokr' || route.name === 'okrHistory'))" v-model="item.schedule" :disabled="(disabled && route.name !== 'myokr' &&  route.name !== 'okrHistory')" :precision="0" :min="0" value-on-clear="min" :max="100" :controls="false" @change="editItemSchdule($event, item.kr_id)"/>
                    <span v-else class="active-text-color schdel">{{item.schedule}}</span>
                    <span class="active-text-color">%</span>
                  </div>

                </li>
              <!-- </transition-group> -->
            </ul>
            <div class="add-kr cursor-pointer" v-if="showAddKrBtn && (templateType === 'add' || isEdit)" @click="addOkrItem"><img src="@/assets/img/work/work-okr-plus.svg">添加KR</div>
          </div>
        </div>
      </main>

      <footer class="okr-template-bottom">
        <div class="okr-visible">
          <!-- 通过状态显示评论 -->
          <div class="cursor-pointer" @click="showComment(okrData)" v-if="comment.includes(templateType) && isAlignTheViewBtn">
            <img src="@/assets/img/common/comment.svg" alt="">
            <span class="comment">评论({{okrData.comment_count}})</span>
          </div>

          <template v-if="!isHighDep">
            <img src="@/assets/img/work/work-okr-view.svg">
            <span v-if="templateType === 'add' || isEdit">可见范围</span>
            <span v-else>{{okrData.scope === 1 ? '全部可见' : '仅本部门和上级可见'}}</span>
            <!-- 新增和编辑显示下拉框 -->
            <el-select v-model="value" placeholder="Select" size="large" v-if="templateType === 'add' || isEdit">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </div>

        <!-- okr模板底部右侧功能按钮 -->
        <div class="okr-submit" v-if="isAlignTheViewBtn">
          <slot name="footer">
            <template v-if="templateType === 'add' || isEdit">
              <el-button @click="submitOkr('submit')">{{isHighDep?'提交':'提交审核'}}</el-button>
              <el-button v-if="draftBtnStatus.includes(templateType)" @click="submitOkr('draft')">保存草稿</el-button>
            </template>
            <template v-else>
              <template v-for="item in btnData[templateType]" :key="item.text">
                <el-tooltip placement="top" v-if="item.tips">
                  <span v-if="!item.check" :class="item.style" @click="getFn(templateType, item.text)">{{item.text}}</span>
                  <template #content>
                    <div class="tooplist-content">
                      {{item.alltext}}
                    </div>
                  </template>
                </el-tooltip>
                <span v-else-if="!item.check" :class="item.style" @click="getFn(templateType, item.text)">{{item.text}}</span>
              </template>
            </template>
          </slot>
        </div>
      </footer>

      <!-- OKR评论列表 -->
      <template v-if="showComent">
        <UserComment
          :id="okrData.id"
          :userId="okrData.user_id"
          commentType="7"
          @updateCommentTotal="updateCommentTotal"
          @hiddenComment="showComent = false"
          >
          </UserComment>
      </template>
    </template>
  </div>
  <el-dialog
    v-model="confirmAuditor"
    title="请选择审核人"
    width="30%"
    custom-class="confirm-auditor-dialog"
  >
  <el-select v-model="leadValue" placeholder="Select" size="large" v-if="templateType === 'add' || isEdit">
    <el-option
      v-for="item in leadData"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="confirmAuditor = false">取消</el-button>
        <el-button type="primary" @click="confirmAuditorSubmit(saveAuditorTemplateData)">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed, reactive, toRefs, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { confirm, dialogMsg } from '@/utils/dialogMsg.js'
import OkrAlignTarget from './OkrAlignTarget.vue'
import AlignmentDialog from '@/views/layout/work/okr/component/AlignmentDialog.vue'
// import { okrDelete, setOkrSchedule, editOkrStatus, getOkrLeader } from '@/apis/okr.js'
import { getOkrCreate, okrDelete, setOkrSchedule, okrUpdate, editOkrStatus, getOkrLeader, saveOkrDraft } from '@/apis/okr.js'
import { feedsFeedComment } from '@/apis/blogs.js'
import mySocket from '@/mixin/socket.js'
import EvaluateTemp from '@/views/layout/work/okr/evaluation/components/EvaluateTemp.vue'
import eventBus from '@/utils/eventBus.js'
import { getAlignName, getAlignId } from '@/utils/okr/okrTools.js'
// import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import UserComment from '@/components/comment/UserComment.vue'

// 保存草稿按钮显示的几种状态
const draftBtnStatus = ['add', 'draft', 'reject', 'pass']

export default {
  components: {
    OkrAlignTarget,
    AlignmentDialog,
    EvaluateTemp,
    UserComment
  },
  emits: ['loadDate', 'revocation', 'uploadOkr', 'deleteOkr', 'delete'],
  props: {
    cycleId: {
      type: [Number, Array]
    },
    date: { // 日期
      type: Object,
      default: () => {}
    },
    showRightBtn: { // 显示右上角编辑按钮
      type: Boolean,
      default: true
    },
    okrData: { // okr 相关数据
      type: Object,
      default: () => {},
      require: true
    },
    isEvaluate: { // 是否为评价页面
      type: Boolean,
      default: false
    },
    isAlignTheViewBtn: { // 对齐视图按钮是否显示
      type: Boolean,
      default: true
    },
    showDepartment: { // 头部是否显示部门
      type: Boolean,
      default: false
    },
    alignDep: { // 对齐视图头部部门
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      isComment: false,
      medalHref: store.state.userInfo.userMedal.image_url,
      okr: computed(() => store.state.okr),
      targetTitle: props.okrData.name, // 标题
      okrData: computed(() => props.okrData), // okr相关数据
      showRightBtn: computed(() => props.showRightBtn), // 右上角按钮
      okrList: computed(() => { // kr列表
        props.okrData.key_results.forEach((i, index, arr) => {
          // eslint-disable-next-line no-unused-expressions
          i.kr_id ? i.kr_id : (i.kr_id = i.id)
          if (props.okrData.status !== 0 && props.okrData.status !== 3) {
            i.id = i.tag?.replace(/[^\d]/g, ' ').trim() || i.id
            i.disabled = false
            arr.length - 1 === index && (i.disabled = true)
          }
          if ((props.okrData.status === 3 && !state.isEdit) || (props.okrData.status === 2)) {
            i.disabled = true
          }
        })
        return props.okrData.key_results
      }),
      cycleId: computed(() => props.cycleId),
      commentType: computed(() => store.state.contentType),
      user: computed(() => store.state.userInfo.userInfos),
      commentListRef: null,
      date: computed(() => props.date), // 日期
      addDate: computed(() => props.date ? `${props.date.year}-${props.date.month}` : ''),
      isEvaluate: computed(() => props.isEvaluate),
      dialogVisible: true,
      comment: ['pass', 'evalution', 'complete'],
      value: 1, // 下拉框可见范围
      disabled: false, // 进度输入框
      templateType: computed(() => { // 对应状态 0-新建okr 1-草稿保存 2-okr提交 3-驳回 4-通过 5-自评 6-完成 7-审核中
        const statusData = [
          { code: 0, value: 'add' },
          { code: 1, value: 'draft' },
          { code: 2, value: 'checking' },
          { code: 3, value: 'reject' },
          { code: 4, value: 'pass' },
          { code: 5, value: 'evalution' },
          { code: 6, value: 'complete' },
          { code: 7, value: 'checking' }
        ]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (props.okrData.status === 1) state.isEdit = false
        if (props.okrData.status === 5 || props.okrData.status === 6) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          state.disabled = true
        }
        if (props.okrData.status === 4 && route.name === 'allTarget') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          state.disabled = true
        }
        return statusData[props.okrData.status || 0].value
      }),
      isEdit: route.params.isEdit ? route.params.isEdit : false, // 是否为编辑状态
      options: [
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '仅本部门和上级可见'
        }
      ],
      alignTarget: false, // 显示对齐弹框 tooplist
      // showCommentList: false,
      alignmentDialogRef: null,
      isAlignTheViewBtn: computed(() => props.isAlignTheViewBtn), //
      showAddKrBtn: computed(() => state.okrList.length < 30),
      delKrId: [], // 编辑状态下删除的krId
      addKrId: [], // 编辑状态下新增的krId
      addCounter: 0, // 编辑状态下 的id负增长
      toEvalutePage: false,
      alignObjId: [], // 对齐部门目标id
      alignKeyResId: [], // 对齐krid
      alignData: props.okrData.align_middle || [], // 对齐返回的内容
      showComent: false,
      showDepartment: computed(() => props.showDepartment),
      alignTextRef: null, // alignText ref
      alignDep: computed(() => props.alignDep),
      btnData: { // 按钮显示信息
        checking: [
          { style: 'cursor-pointer bg-active-color', text: '该目标正在审核中' },
          { style: 'cursor-pointer active-text-color', text: '撤销审核' }
        ],
        draft: [
          { style: 'cursor-pointer bg-draft-color', text: '草稿' }
        ],
        reject: [
          { style: 'cursor-pointer bg-reject-color reject-text oneline-overflow', text: `目标审核被驳回：${props.okrData.reject_content?.length > 14 ? props.okrData.reject_content.slice(0, 14) + '...' : props.okrData.reject_content}`, tips: true, alltext: props.okrData.reject_content }
        ],
        pass: [
          { style: 'cursor-pointer bg-agree-color', text: '该目标已审核通过', check: computed(() => (route.params.depName === process.env.VUE_APP_COMPANY_NAME) || (route.query.department === process.env.VUE_APP_COMPANY_NAME)) }
        ],
        evalution: [
          { style: 'cursor-pointer evalution-btn', text: '去评价' }
        ]
      },
      saveData: {}, // 草稿数据
      isHighDep: computed(() => route.params.depName === process.env.VUE_APP_COMPANY_NAME),
      alignText: props.okrData.align_name || '对齐上级目标',
      leaveRouter: true,
      limit: 10,
      confirmAuditor: false,
      leadData: [],
      leadValue: '',
      saveAuditorTemplateData: [],
      showTargetType: ['allTarget', 'targetAligned', 'evaluation']
    })

    // 取消审核
    const cancelAudit = () => {
      confirm({
        message: '是否撤销审核',
        success: function () {
          // 变回草稿重新修改
          editOkrStatus({ id: state.okrData.id, status: 1 }).then(({ code, msg }) => {
            if (code === 0) {
              emit('uploadOkr')
              eventBus.$emit('uploadCycle', state.cycleId)
            } else {
              dialogMsg('error', msg)
            }
          })
        },
        cancel: () => {}
      })
    }

    const toEndCycle = () => {
      // console.log(1)
      // 修改状态
      store.commit('okr/setstatus', { id: state.okrData.id, status: 5 })
      emit('uploadOkr')
    }
    // 去评价
    const goToEvalute = (id) => {
      state.toEvalutePage = !state.toEvalutePage
    }

    watch(() => state.isAlignTheViewBtn, (newValue) => {
      newValue === false && (state.disabled = true)
    }, { immediate: true })

    // 展开评论
    const showComment = () => {
      state.page = 1
      state.showComent = !state.showComent
      // 调用接口
      // if (state.showComent && state.okrData.id) {
      //   state.commentListRef.getCommentList()
      // }
    }

    const updateCommentTotal = (total) => {
      state.okrData.comment_count = total
    }

    const { sendToSocket } = mySocket()

    // 新增评论
    const addComment = (msg, item) => {
      const data = {
        id: item.id,
        commentable_type: 7,
        body: msg.value,
        target_user: item.user_id,
        at_user: msg.at_user,
        images_url: msg.images_url
      }
      feedsFeedComment(data).then((res) => {
        if (res.code === 0) {
          dialogMsg('success', '评论成功')
          const socketArr = [item.user_id]
          sendToSocket(socketArr, { type: 5 })
          sendToSocket(msg.at_user, { type: 4 })
          // 刷新页面数据
          state.commentListRef.getCommentList()
        } else {
          dialogMsg('error', res.msg)
        }
      })
        .catch((err) => {
          console.log(err)
        })
    }

    const forbiddeEnter = (e) => {
      if (e.keyCode !== 13) return
      e.preventDefault()
    }

    // 控制对齐框显示何隐藏
    const handleClick = (e) => {
      if (state.alignTextRef && !state.alignTextRef.contains(e.target)) {
        state.alignTarget = false
      }
    }

    onBeforeRouteLeave((to, from, next) => {
      if ((state.templateType === 'add' || state.isEdit) && state.leaveRouter && state.saveData.scope && !to.query.ignoreQuit) {
        quitEdit('okrAdd', next)
      } else {
        next()
      }
    })

    onMounted(() => {
      document.querySelector('.el-textarea__inner') && document.querySelector('.el-textarea__inner').addEventListener('keydown', forbiddeEnter)
      document.addEventListener('click', handleClick)
      state.value = state.okrData?.scope || 1
      // 历史okr 不可编辑
      route.name === 'okrHistory' && (state.disabled = true)
      route.name === 'okr-add' && (state.saveData = getData()) // 初始化本地保存数据

      if (route.params.showComent) {
        showComment(state.okrData)
      }
    })

    onBeforeUnmount(() => {
      document.querySelector('.el-textarea__inner') && document.querySelector('.el-textarea__inner').removeEventListener('keydown', forbiddeEnter)
      document.removeEventListener('click', handleClick)
    })

    watch(() => state.templateType, (newValue) => {
      if (newValue !== 'add' && state.isEdit === false) {
        state.okrList.map(i => (i.disabled = true))
      }
    }, { immediate: true })

    // 新增kr
    const addOkrItem = () => {
      // 非新增添加 保存新增的id 取负号
      state.addCounter = state.addCounter - 1
      state.okrList.map(i => (i.disabled = false))
      state.okrList.push({
        id: Number(state.okrList.length) + 1,
        content: '',
        weights: 0,
        disabled: true,
        tag: 'KR' + (Number(state.okrList.length) + 1),
        isEditAdd: state.isEdit ? true : undefined,
        isEditAddId: state.isEdit ? state.addCounter : undefined
      })
    }

    // 删除kr
    const deleteOkrItem = (index, item) => {
      if (state.okrList.length === 1) return dialogMsg('error', '必须添加一个kr')
      // 非新增删除 需要保存删除的id
      state.isEdit && !item.isEditAdd && state.delKrId.push(item.kr_id)

      state.okrList.splice(index, 1)
      state.okrList[state.okrList.length - 1].disabled = true
      // 重新计算权重
      changeWeight(index - 1)
    }

    // 修改权重
    const changeWeight = (currentIndex) => {
      const setWeight = () => {
        let temp = 0
        state.okrList.forEach((i, index, arr) => {
          if (arr.length - 1 !== index) {
            temp += i.weights
          }
        })
        return temp
      }
      // 获取当前输入的
      let currentWeight = 0
      // 获取其他的
      let otherWeights = 0
      const lastWeight = state.okrList[state.okrList.length - 1].weights
      state.okrList.forEach((i, index, arr) => {
        if (!i.weights) i.weights = 0
        index === currentIndex ? (currentWeight = i.weights) : (otherWeights += i.weights)
      })
      // console.log(currentWeight, otherWeights)
      // 当前的权重 < 剩余权重 不加最后一项 赋值给最后一项   如果 > 剩余权重 清空中间 赋值最后一项
      if ((100 - currentWeight) < (otherWeights - lastWeight)) {
        // 清空中间
        state.okrList.map((i, index) => index > currentIndex && (i.weights = 0))
        // 赋值最后一项
        state.okrList[state.okrList.length - 1].weights = 100 - setWeight()
      } else {
        state.okrList[state.okrList.length - 1].weights = 100 - setWeight()
      }
    }

    // 获取最大权重
    const getMaxNumber = (currentIndex) => {
      // 筛选出不是当前的
      const tempList = state.okrList.filter((i, index) => index < currentIndex)
      let tempWeight = 0
      tempList.map(i => (tempWeight += i.weights))
      return 100 - tempWeight
    }

    // 如果不输入为0 权重默认设置为0
    const inputNumberBlur = (currentIndex) => {
      const okrItem = state.okrList.filter((i, index) => index === currentIndex)[0]
      if (!okrItem.weights) okrItem.weights = 0
    }

    // 取消创建
    const quitEdit = (type, next) => {
      if (type !== 'okrAdd') {
        state.isEdit = false
        route.params.isEdit = false
        emit('uploadOkr')
        return false
      }
      // 检测是否保存草稿
      const data = getData()
      let message = ''
      // console.log(JSON.stringify(data), JSON.stringify(state.saveData))
      JSON.stringify(data) === JSON.stringify(state.saveData) ? (message = '是否取消当前okr的创建') : (message = '页面发生变更, 是否不保存草稿退出')
      confirm({
        message: message,
        success: function () {
          if (next) {
            next()
          } else {
            state.saveData = {}
            type === 'okrAdd' ? router.push({ name: 'myokr', params: { id: route.params.id, depName: route.params.depName } }) : emit('uploadOkr')
          }
        },
        cancel: () => {}
      })
    }

    // 编辑okr
    const editOkr = () => {
      // debugger
      state.isEdit = !state.isEdit
      if (state.isEdit) {
        state.okrList.map((i, index, arr) => {
          i.disabled = false
          index === arr.length - 1 && (i.disabled = true)
        })
      } else {
        state.okrList.map(i => (i.disabled = true))
      }
      state.saveData = getData() // 点击编辑 更新本地okr对比数据
    }

    // 获取数据
    const getData = (type) => {
      const data = {
        cycle_id: state.cycleId,
        dept_id: route.params.id,
        name: state.targetTitle,
        scope: state.value,
        number: [],
        key_result_name: [],
        weights: [],
        align_name: state.alignText
      }

      state.okrList.map((i, index) => {
        data.number.push(index + 1)
        data.key_result_name.push(i.name)
        data.weights.push(i.weights)
      })

      if (state.isEdit) {
        data.id = state.okrData.id
        // 删除的kr id
        data.del_kr_id = state.delKrId
        // 新增的kr 组成数组
        data.kr_id = []
        state.okrList.filter(i => !i.isEditAdd).map(j => data.kr_id.push(j.kr_id))
        state.okrList.filter(i => i.isEditAdd).map(j => data.kr_id.push(j.isEditAddId))
      }
      data.type = type
      if (state.alignObjId.length) { // 自己保存的对齐的目标id
        data.align_objective_id = state.alignObjId
      }

      if (state.alignKeyResId.length) { // 自己保存的krid
        data.align_key_result_id = state.alignKeyResId
      }

      const templateList = ['pass', 'reject', 'draft']
      if (
        state.alignData?.length &&
        (!data.align_key_result_id || !data.align_objective_id) &&
        ((state.isEdit && (templateList.includes(state.templateType))))
      ) { // 更新okr 对齐id
        const { objId, krID } = getAlignId(state.alignData)
        data.align_objective_id = objId
        data.align_key_result_id = krID
      }
      return data
    }

    // 保存okr
    const saveOkr = (data) => {
      data.status = 1
      saveOkrDraft(data).then(({ code, msg }) => {
        if (code === 0) {
          state.saveData = data // 用于保存检测是否保存
          dialogMsg('success', '保存成功')
          state.leaveRouter = false
          // console.log(route.name)
          if (route.name === 'okr-add') {
            // router.go(-1)
            router.push({ name: 'myokr', params: { id: route.params.id, depName: route.params.depName } })
          }
          eventBus.$emit('saveDraft')
        } else {
          dialogMsg('error', msg)
        }
      })
    }

    // submitSuccessOkr 提交成功
    const submitOkrRequest = (loadData) => {
      loadData.status = 2
      getOkrLeader({ dept_id: loadData.dept_id }).then(res => {
        if (res.code === 0 && res.data.length > 1) {
          // console.log(res.data)
          const leadArr = res.data.map(item => { return { value: item.id, label: item.name } })
          state.leadData = leadArr
          state.leadValue = leadArr[0].value
          state.confirmAuditor = true
          state.saveAuditorTemplateData = loadData
        } else {
          state.leadValue = res.data[0] && res.data[0].id
          confirmAuditorSubmit(loadData)
        }
      })
    }
    // 提交okr
    const confirmAuditorSubmit = (data) => {
      data.lead_user_id = state.leadValue || undefined
      // websocket通知
      if ((state.isEdit && state.templateType === 'pass') || (state.isEdit && state.templateType === 'reject')) {
        state.isHighDep && (data.status = 4)
        return okrUpdate(data).then(({ code, msg }) => {
          if (code === 0) {
            dialogMsg('success', '提交成功')
            state.isEdit && (state.isEdit = false)
            state.saveData = {}
            emit('uploadOkr')
            // 更新周期
            eventBus.$emit('uploadCycle', state.cycleId)
            state.confirmAuditor = false
            data.lead_user_id && sendToSocket(data.lead_user_id, 8)
          } else {
            dialogMsg('error', msg)
          }
        })
      }
      getOkrCreate(data).then(({ code, msg }) => {
        if (code === 0) {
          dialogMsg('success', '提交成功')
          eventBus.$emit('getOkrList', state.date.month)
          state.saveData = {}
          // route.name === 'okr-add' ? router.go(-1) : emit('uploadOkr')
          route.name === 'okr-add' ? router.push({ name: 'myokr', params: { id: route.params.id, depName: route.params.depName } }) : emit('uploadOkr')
          // 更新周期
          eventBus.$emit('uploadCycle', state.cycleId)
          data.lead_user_id && sendToSocket(data.lead_user_id, 8)
        } else {
          dialogMsg('error', msg)
        }
      })
    }

    // 提交 创建okr
    const submitOkr = (subtype) => {
      // debugger
      if (subtype === 'submit') {
        if (!state.targetTitle) return dialogMsg('error', '请输入目标')
        const noContentIndex = state.okrList.findIndex(i => !i.name)
        if (noContentIndex !== -1) return dialogMsg('error', `kr${noContentIndex + 1}目标为空`)
        const noWeight = state.okrList.findIndex(i => !i.weights)
        if (noWeight !== -1) return dialogMsg('error', `kr${noWeight + 1}权重为0`)
      }
      // 更新okr 非创建状态重新提交
      const data = getData(subtype)
      // 提交okr
      subtype === 'submit' ? submitOkrRequest(data) : saveOkr(data)
    }

    // 显示对齐目标
    const showAlign = () => {
      if (state.templateType === 'add' || state.isEdit) return state.alignmentDialogRef.open()
      state.alignTarget = !state.alignTarget
      // state.alignTarget = !state.alignTarget
    }

    // 对齐目标选择
    const alignment = (item) => {
      state.alignData = []
      state.alignObjId = []
      state.alignKeyResId = []
      // console.log(item)
      state.alignData = item
      item.forEach(i => {
        i.key_results.map(j => j.align_objective_id ? (state.alignObjId.includes(j.align_objective_id) ? '' : state.alignObjId.push(j.align_objective_id)) : (state.alignKeyResId.includes(j.id) ? '' : state.alignKeyResId.push(j.id)))
      })
      state.alignText = getAlignName(state.alignData)
    }

    // 删除目标
    const deleteOkr = (id) => {
      // console.log(id)
      confirm({
        message: state.templateType === 'draft' ? '是否删除草稿' : '是否删除目标',
        success: function () {
          okrDelete({ id }).then(({ code, data, msg }) => {
            if (code === 0) {
              dialogMsg('success', '删除成功')
              emit('deleteOkr', id)
            } else {
              dialogMsg('error', msg)
            }
          })

          setTimeout(() => {
            router.push({ name: 'myokr', params: { refresh: true } })
          })
        },
        cancel: () => {}
      })
    }

    // 修改当前进度
    const editItemSchdule = (currentValue, id) => {
      setOkrSchedule({ id, schedule: currentValue }).then(res => {
        if (res.code === 0) {
          dialogMsg('success', res.msg)
          state.okrData.schedule = res.data.schedule
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }

    if (state.okrData.queryType === 'evalute') {
      goToEvalute()
    }

    // 评价成成功
    const evaluateSuccess = () => {
      emit('uploadOkr')
    }

    // 获得cycle周期显示部门
    const getCycle = (cycle) => {
      if (state.isAlignTheViewBtn === false) return `${state.alignDep}-${cycle.length > 9 ? cycle.slice(0, -2) : cycle}`
      return cycle.length > 9 && (route.name !== 'evaluation') ? cycle.slice(0, -2) : cycle
    }

    const getFn = (type, text) => {
      switch (type) {
        case 'checking':
          text === '撤销审核' && cancelAudit()
          break
        case 'pass':
          process.env.VUE_APP_NODE_ENV !== 'production' && toEndCycle()
          break
        case 'evalution':
          goToEvalute()
          break
        default:
          console.log(false)
      }
    }
    return {
      ...toRefs(state),
      draftBtnStatus,
      deleteOkrItem,
      addOkrItem,
      changeWeight,
      quitEdit,
      getMaxNumber,
      inputNumberBlur,
      editOkr,
      showAlign,
      showComment,
      submitOkr,
      cancelAudit,
      deleteOkr,
      alignment,
      editItemSchdule,
      goToEvalute,
      evaluateSuccess,
      updateCommentTotal,
      addComment,

      route,
      toEndCycle,
      getCycle,
      getFn,
      confirmAuditorSubmit
    }
  }
}
</script>

<style lang="less" scoped>
@import url(../../../../../../assets/less/okrTemplate.less);
</style>
<style lang="less">
.okr-template .comment-list .comment-left .user-medal:before {
  transform: translate(-1px, 5%) !important;
}
</style>
